const favoritesReducer = (state = [], action) => {
  switch (action.type) {
    case "CLEAR_FAVORITES":
      return [];
    case "REMOVE_FAVORITES":
      let newState = [...state];
      return newState.filter((s) => !action.keys.includes(s.key));
    case "REMOVE_FAVORITE":
      if (action.index !== -1) {
        let newState = [...state];
        newState.splice(action.index, 1);
        return newState;
      }
      return state;
    case "ADD_FAVORITE":
      var match = state.find(
        (favorite) => favorite.key === action.favorite.key
      );
      if (match) {
        return state;
      }
      return state.concat([action.favorite]);
    default:
      return state;
  }
};

export default favoritesReducer;
