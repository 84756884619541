import Menu from "./components/Menu";
import Login from "./pages/Login";
import Player from "./pages/Player";
import Screenshots from "./pages/Screenshots";
import Settings from "./pages/Settings";
import Favorites from "./pages/Favorites";
import React, { useEffect } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonImg,
  IonContent,
  IonSpinner,
  IonPage,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { initAuthCheck, initDeviceCheck } from "./actions";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/global.css";
import "./theme/variables.css";

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const id = useSelector((state) => state.id);
  const platform = useSelector((state) => state.platform);

  useEffect(() => {
    dispatch(initDeviceCheck());
    dispatch(initAuthCheck());
  }, [dispatch]);

  function login() {
    return <Login />;
  }

  function app() {
    return (
      <IonReactRouter>
        <Menu />
        <IonRouterOutlet id="main">
          <Route path="/player/" component={Player} />
          <Route path="/favorites/" component={Favorites} />
          <Route path="/screenshots/" component={Screenshots} />
          <Route path="/settings/" component={Settings} />
          <Route
            path="/"
            render={() => <Redirect to="/player/" />}
            exact={true}
          />
        </IonRouterOutlet>
      </IonReactRouter>
    );
  }

  return (
    <IonApp className={platform}>
      {!auth.checked ? (
        <IonPage>
          <IonContent>
            <div id="login-page">
              <div id="login-form">
                <div id="logo-container">
                  <IonImg src="/viewmedica.svg" />
                </div>
                <div className="loading-container">
                  <IonSpinner name="crescent" color="light" />
                </div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      ) : id ? (
        app()
      ) : (
        login()
      )}
    </IonApp>
  );
};

export default App;
