const logoReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_LOGO":
      if (
        (action.logo.includes("clientupload/") || action.logo.includes("slides/") || action.logo.includes("media/")) &&
        !action.logo.includes("viewmedica.com") &&
        !action.logo.includes("swarminteractive.com")
      ) {
        return `https://api.viewmedica.com/${action.logo}`;
      }
      return action.logo;
    default:
      return state;
  }
};

export default logoReducer;
