const authReducer = (
  state = {
    checked: false,
    checking: false,
  },
  action
) => {
  switch (action.type) {
    case "AUTH_CHECK":
      return Object.assign({}, state, { checking: true });
    case "AUTH_CHECKED":
      return Object.assign({}, state, { checked: true, checking: false });
    case "AUTH_RESET":
      return Object.assign({}, state, { checked: false, checking: false });
    default:
      return state;
  }
};

export default authReducer;
