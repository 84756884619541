const platformReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_PLATFORM":
      return action.platform;
    default:
      return state;
  }
};

export default platformReducer;

