const initialState = {
  loading: false,
  items: [],
  mru: null,
};

const screenshotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MOST_RECENT":
      console.log("MOST_RECENT", action);
      return Object.assign({}, state, {
        mru: action.uri,
      });

    case "SCREENSHOTS_LOAD_START":
      console.log("LOAD START");
      return Object.assign({}, state, {
        loading: true,
      });

    case "SET_SCREENSHOTS":
      console.log("SET SCREENSHOTS");
      return Object.assign({}, state, {
        items: action.screenshots,
      });

    case "SCREENSHOTS_LOAD_STOP":
      console.log("LOAD STOP");
      return Object.assign({}, state, {
        loading: false,
      });

    default:
      return state;
  }
};

export default screenshotsReducer;
