import {
  IonPage,
  IonList,
  IonItem,
  IonContent,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonHeader,
  IonFooter,
} from "@ionic/react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDisclaimer,
  setLanguage,
  setBrochure,
  setSubtitles,
  setCaptions,
  setMarkup,
  setSearch,
  setSharing,
  setSections,
} from "../actions";
import "./Page.css";
import { useTranslation } from "react-i18next";

const Page = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const color = useSelector((state) => state.color);
  const disclaimer = useSelector((state) => state.disclaimer);
  const language = useSelector((state) => state.language);
  const brochure = useSelector((state) => state.brochure);
  const subtitles = useSelector((state) => state.subtitles);
  const captions = useSelector((state) => state.captions);
  const markup = useSelector((state) => state.markup);
  const search = useSelector((state) => state.search);
  const sections = useSelector((state) => state.sections);
  const sharing = useSelector((state) => state.sharing);
  const menuButtonstyle = {
    color: `#${color}`,
  };

  const mapLanguage = {
    en: "English",
    es: "Spanish",
    de: "German",
  };

  const mapSettings = {
    "": "Default",
    true: "On",
    false: "Off",
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton class="menu-button" style={menuButtonstyle} />
          </IonButtons>
          <IonTitle>{t("Settings")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>{t("Language")}</IonLabel>
            <IonSelect
              cancelText={t("Cancel")}
              value={language}
              selectedText={t(mapLanguage[language])}
              onIonChange={(e) => {
                dispatch(setLanguage(e.detail.value));
                i18n.changeLanguage(e.detail.value);
              }}
            >
              <IonSelectOption value="en">
                <p>{t("English")}</p>
              </IonSelectOption>
              <IonSelectOption value="es">{t("Spanish")}</IonSelectOption>
              {/* <IonSelectOption value="de">{t('German')}</IonSelectOption> */}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Brochure")}</IonLabel>
            <IonSelect
              selectedText={t(mapSettings[brochure])}
              cancelText={t("Cancel")}
              value={brochure}
              onIonChange={(e) => dispatch(setBrochure(e.detail.value))}
            >
              <IonSelectOption value="">{t("Default")}</IonSelectOption>
              <IonSelectOption value="true">{t("On")}</IonSelectOption>
              <IonSelectOption value="false">{t("Off")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Subtitles")}</IonLabel>
            <IonSelect
              selectedText={t(mapSettings[subtitles])}
              cancelText={t("Cancel")}
              value={subtitles}
              onIonChange={(e) => dispatch(setSubtitles(e.detail.value))}
            >
              <IonSelectOption value="">{t("Default")}</IonSelectOption>
              <IonSelectOption value="true">{t("On")}</IonSelectOption>
              <IonSelectOption value="false">{t("Off")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("CC Button")}</IonLabel>
            <IonSelect
              selectedText={t(mapSettings[captions])}
              cancelText={t("Cancel")}
              value={captions}
              onIonChange={(e) => dispatch(setCaptions(e.detail.value))}
            >
              <IonSelectOption value="">{t("Default")}</IonSelectOption>
              <IonSelectOption value="true">{t("On")}</IonSelectOption>
              <IonSelectOption value="false">{t("Off")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Disclaimer")}</IonLabel>
            <IonSelect
              selectedText={t(mapSettings[disclaimer])}
              cancelText={t("Cancel")}
              value={disclaimer}
              onIonChange={(e) => dispatch(setDisclaimer(e.detail.value))}
            >
              <IonSelectOption value="">{t("Default")}</IonSelectOption>
              <IonSelectOption value="true">{t("On")}</IonSelectOption>
              <IonSelectOption value="false">{t("Off")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Markup")}</IonLabel>
            <IonSelect
              selectedText={t(mapSettings[markup])}
              cancelText={t("Cancel")}
              value={markup}
              onIonChange={(e) => dispatch(setMarkup(e.detail.value))}
            >
              <IonSelectOption value="">{t("Default")}</IonSelectOption>
              <IonSelectOption value="true">{t("On")}</IonSelectOption>
              <IonSelectOption value="false">{t("Off")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Search")}</IonLabel>
            <IonSelect
              selectedText={t(mapSettings[search])}
              cancelText={t("Cancel")}
              value={search}
              onIonChange={(e) => dispatch(setSearch(e.detail.value))}
            >
              <IonSelectOption value="">{t("Default")}</IonSelectOption>
              <IonSelectOption value="true">{t("On")}</IonSelectOption>
              <IonSelectOption value="false">{t("Off")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Sections")}</IonLabel>
            <IonSelect
              selectedText={t(mapSettings[sections])}
              cancelText={t("Cancel")}
              value={sections}
              onIonChange={(e) => dispatch(setSections(e.detail.value))}
            >
              <IonSelectOption value="">{t("Default")}</IonSelectOption>
              <IonSelectOption value="true">{t("On")}</IonSelectOption>
              <IonSelectOption value="false">{t("Off")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Sharing")}</IonLabel>
            <IonSelect
              selectedText={t(mapSettings[sharing])}
              cancelText={t("Cancel")}
              value={sharing}
              onIonChange={(e) => dispatch(setSharing(e.detail.value))}
            >
              <IonSelectOption value="">{t("Default")}</IonSelectOption>
              <IonSelectOption value="true">{t("On")}</IonSelectOption>
              <IonSelectOption value="false">{t("Off")}</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonLabel class="copyright-version" slot="start">
            Version 2.1.6
          </IonLabel>
          <IonLabel class="copyright-version" slot="end">
            © 2022 Swarm Interactive, Inc.
          </IonLabel>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Page;
