import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import allReducers from "./reducers";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import { loadState, saveState } from "./storage";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Network } from "@capacitor/network";
import { setNetwork } from "./actions";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

loadState().then((persistedState) => {
  const store = createStore(
    allReducers,
    persistedState,
    composeEnhancer(applyMiddleware(ReduxThunk))
  );

  store.subscribe(() => {
    saveState(store.getState());
  });

  // the translations
  // (tip move them in a JSON file and import them)
  const resources = {
    en: {
      translation: {
        Brochure: "Brochure",
        Cancel: "Cancel",
        "CC Button": "CC Button",
        Default: "Default",
        Disclaimer: "Disclaimer",
        English: "English",
        "Your favorites are empty": "Your favorites are empty",
        "Your screenshots are empty": "Your screenshots are empty",
        'Go to your player, open a video or presentation, and select "Add to Favorites" to get started.':
          'Go to your player, open a video or presentation, and select "Add to Favorites" to get started.',
        "Make screenshots by using Markup in a playing video and hitting the camera icon.":
          "Make screenshots by using Markup in a playing video and hitting the camera icon.",
        False: "False",
        Favorites: "Favorites",
        German: "German",
        Language: "Language",
        Logout: "Logout",
        Markup: "Markup",
        On: "On",
        Off: "Off",
        Password: "Password",
        Player: "Player",
        Reset: "Reset",
        Screenshots: "Screenshots",
        Search: "Search",
        Sections: "Sections",
        Settings: "Settings",
        Sharing: "Sharing",
        "Sign In": "Sign In",
        Spanish: "Spanish",
        Subtitles: "Subtitles",
        True: "True",
        Username: "Username",
        "Video Library": "Video Library",
      },
    },
    es: {
      translation: {
        Brochure: "Folleto",
        Cancel: "Cancelar",
        "CC Button": "CC Botón",
        Default: "Defecto",
        Disclaimer: "Renuncia",
        English: "Inglés",
        "Your favorites are empty": "Tus favoritos están vacíos",
        "Your screenshots are empty": "Tus capturas de pantalla están vacíos",
        'Go to your player, open a video or presentation, and select "Add to Favorites" to get started.':
          'Vaya a su reproductor, abra un video o una presentación y seleccione "Agregar a favoritos" para comenzar.',
        "Make screenshots by using Markup in a playing video and hitting the camera icon.":
          "Realice capturas de pantalla utilizando Markup en un video en reproducción y presionando el ícono de la cámara.",
        False: "Falso",
        Favorites: "Favoritos",
        German: "Alemán",
        Language: "Idioma",
        Logout: "Cerrar Sesión",
        Markup: "Margen",
        On: "En",
        Off: "Apagado",
        Password: "Contraseña",
        Player: "Reproductor",
        Reset: "Reiniciar",
        Search: "Buscar",
        Screenshots: "Captura de pantalla",
        Sections: "Secciones",
        Settings: "Ajustes",
        Sharing: "Comparte",
        "Sign In": "Registrarse",
        Spanish: "Español",
        Subtitles: "Subtítulos",
        True: "Cierto",
        Username: "Nombre de Usuario",
        "Video Library": "Videoteca",
      },
    },
    de: {
      translation: {
        Brochure: "Broschüre",
        Cancel: "Stornieren",
        "CC Button": "CC Taste",
        Default: "Standard",
        Disclaimer: "Haftungsausschluss",
        English: "Englisch",
        "Your favorites are empty": "Ihre Favoriten sind leer.",
        "Your screenshots are empty": "Ihre Screenshots sind leer",
        'Go to your player, open a video or presentation, and select "Add to Favorites" to get started.':
          'Gehen Sie zu Ihrem Player, öffnen Sie ein Video oder eine Präsentation und wählen Sie "Zu Favoriten hinzufügen", um zu beginnen.',
        "Make screenshots by using Markup in a playing video and hitting the camera icon.":
          "Erstellen Sie Screenshots, indem Sie Markup in einem abspielenden Video verwenden und auf das Kamerasymbol klicken.",
        False: "Falsch",
        Favorites: "Favoriten",
        German: "Deutsche",
        Language: "Sprache",
        Logout: "Ausloggen",
        Markup: "Markup",
        On: "Auf",
        Off: "Aus",
        Password: "Passwort",
        Player: "Spieler",
        Reset: "Zurücksetzen",
        Screenshots: "Bildschirmfoto",
        Search: "Suche",
        Sections: "Abschnitte",
        Settings: "Einstellungen",
        Sharing: "Teilen",
        "Sign In": "Einloggen",
        Spanish: "Spanisch",
        Subtitles: "Untertitel",
        True: "Wahr",
        Username: "Nutzername",
        "Video Library": "Videobibliothek",
      },
    },
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: store.getState().language,
      debug: false,
      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </Provider>,
    document.getElementById("root")
  );

  async function getInitialNetworkStatus() {
    const initialStatus = await Network.getStatus();
    store.dispatch(setNetwork(initialStatus.connected));
  }

  getInitialNetworkStatus();

  Network.addListener("networkStatusChange", (status) => {
    console.log("Network status changed", status);
    store.dispatch(setNetwork(status.connected));
  });

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
});
