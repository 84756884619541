import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  starOutline,
  starSharp,
  playCircleOutline,
  cameraOutline,
  cameraSharp,
  settingsOutline,
  playCircleSharp,
  settingsSharp,
  logOutOutline,
} from "ionicons/icons";
import "./Menu.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setToken,
  setPractice,
  setLogo,
  setColor,
  setId,
  clearScreenshots,
  clearFavorites,
  setDisclaimer,
  setLanguage,
  setBrochure,
  setSubtitles,
  setCaptions,
  setMarkup,
  setSearch,
  setSharing,
  setSections,
} from "../actions";
import { useTranslation } from "react-i18next";

const appPages = [
  {
    title: "Video Library",
    url: "/player/",
    class: "player",
    iosIcon: playCircleOutline,
    mdIcon: playCircleSharp,
  },
  {
    title: "Favorites",
    url: "/favorites/",
    class: "favorites",
    iosIcon: starOutline,
    mdIcon: starSharp,
  },
  {
    title: "Screenshots",
    url: "/screenshots/",
    class: "screenshots",
    iosIcon: cameraOutline,
    mdIcon: cameraSharp,
  },
  {
    title: "Settings",
    class: "settings",
    url: "/settings/",
    iosIcon: settingsOutline,
    mdIcon: settingsSharp,
  },
];

const Menu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logo = useSelector((state) => state.logo);
  const practice = useSelector((state) => state.practice);
  const history = useHistory();

  const identity = () => {
    if (logo) {
      return (
        <div className="logo">
          <img className="player-logo" src={logo} alt="Logo" />
        </div>
      );
    } else {
      return <IonLabel id="practice-name">{practice}</IonLabel>;
    }
  };

  async function logout() {
    dispatch(clearScreenshots());
    dispatch(setColor("000000"));
    dispatch(setLogo(""));
    dispatch(setId(0));
    dispatch(setToken(""));
    dispatch(setPractice(""));
    dispatch(clearFavorites());
    dispatch(setDisclaimer(""));
    dispatch(setLanguage("en"));
    dispatch(setBrochure(""));
    dispatch(setSubtitles(""));
    dispatch(setCaptions(""));
    dispatch(setMarkup(""));
    dispatch(setSearch(""));
    dispatch(setSharing(""));
    dispatch(setSections(""));
    var vm = window.document.querySelector("#vm");
    if (vm) {
      vm.innerHTML = "";
    }
    history.replace("/");
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          {identity()}

          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={appPage.class}
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{t(appPage.title)}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}

          <IonMenuToggle autoHide={false}>
            <IonItem onClick={() => logout()} lines="none" detail={false}>
              <IonIcon slot="start" icon={logOutOutline} />
              <IonLabel>{t("Logout")}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
