import { Storage } from "@capacitor/storage";
const removeKeys = ["auth", "screenshots"];

export const loadState = async () => {
  try {
    const serializedState = await Storage.get({ key: "state" });
    if (serializedState.value === null) {
      return undefined;
    }
    return JSON.parse(serializedState.value);
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const saveState = async (state) => {
  const filteredState = Object.assign({}, state);
  removeKeys.forEach((k) => {
    delete filteredState[k];
  });
  console.log(filteredState);
  try {
    const serializedState = JSON.stringify(filteredState);
    Storage.set({ key: "state", value: serializedState });
  } catch (err) {}
};
