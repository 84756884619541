// import { Plugins } from '@capacitor/core';

// const { Storage } = Plugins;

// async function storeToken (token) {
//   await Storage.set({
//     key: 'token',
//     value: token
//   });
// };

// async function retrieveToken () {
//   const { value } = await Storage.get({ key: 'token' });
//   return value;
// }

// let defaultToken = "";
// retrieveToken().then(token => defaultToken = token);

const tokenReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return action.token;
    default:
      return state;
  }
};

export default tokenReducer;
