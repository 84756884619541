const brochureReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_BROCHURE":
      return action.brochure;
    default:
      return state;
  }
};

export default brochureReducer;
