const subtitlesReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_SUBTITLES":
      return action.subtitles;
    default:
      return state;
  }
};

export default subtitlesReducer;
