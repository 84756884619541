const markupReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_MARKUP":
      return action.markup;
    default:
      return state;
  }
};

export default markupReducer;
