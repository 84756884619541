const defaultColor = "000000";

const colorReducer = (state = defaultColor, action) => {
  switch (action.type) {
    case "SET_COLOR":
      return action.color;
    default:
      return state;
  }
};

export default colorReducer;
