const sharingReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_SHARING":
      return action.sharing;
    default:
      return state;
  }
};

export default sharingReducer;
