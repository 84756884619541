const practiceReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_PRACTICE":
      return action.practice;
    default:
      return state;
  }
};

export default practiceReducer;
