const disclaimerReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_DISCLAIMER":
      return action.disclaimer;
    default:
      return state;
  }
};

export default disclaimerReducer;
