const captionsReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_CAPTIONS":
      return action.captions;
    default:
      return state;
  }
};

export default captionsReducer;
