import {
  IonPage,
  IonList,
  IonItem,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonSpinner,
  IonContent,
  IonFooter,
  IonText,
  IonIcon,
} from "@ionic/react";
import { checkmarkCircleSharp, ellipseOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import "./Page.css";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { removeFile, openFile, getUri } from "../filesystem";
import { loadScreenshots, clearScreenshots } from "../actions";

const Screenshots = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const color = useSelector((state) => state.color);
  const loading = useSelector((state) => state.screenshots.loading);
  const screenshots = useSelector((state) => state.screenshots.items);
  const [editing, setEditing] = useState(false);
  const [selections, setSelections] = useState([]);
  const menuButtonstyle = {
    color: `#${color}`,
  };

  useEffect(() => {
    dispatch(loadScreenshots());
  }, [dispatch]);

  function fetchScreenshots() {
    dispatch(loadScreenshots());
  }

  async function openScreenshot(file) {
    try {
      const uri = await getUri({
        directory: "screenshots",
        file: file,
      });
      await openFile(uri);
    } catch (e) {
      console.error(e);
    }
  }

  async function removeScreenshot(filename) {
    await removeFile({
      filename,
      directory: "screenshots",
    });
    fetchScreenshots();
  }

  const toggleEdit = function () {
    setEditing(!editing);
    if (!editing) {
      clearSelections();
    }
  };

  const toggleSelect = function (i) {
    if (selected(i)) {
      let selected = selections.slice(0);
      selected.splice(selected.indexOf(i), 1);
      setSelections(selected);
    } else {
      setSelections(selections.concat([i]));
    }
  };

  const clearSelections = function () {
    setSelections([]);
  };

  const selected = function (i) {
    return selections.indexOf(i) > -1;
  };

  const remove = async function () {
    if (selections.length) {
      for (var filename of selections) {
        console.log(`remove ${filename}`);
        await removeFile({
          filename,
          directory: "screenshots",
        });
      }
    } else {
      dispatch(clearScreenshots());
    }

    fetchScreenshots();
    setEditing(false);
  };

  function list() {
    return screenshots.length ? (
      <IonList>
        {screenshots.map((screenshot) => {
          return (
            <IonItemSliding key={screenshot}>
              <IonItemOptions side="end">
                <IonItemOption
                  color="danger"
                  onClick={() => removeScreenshot(screenshot)}
                >
                  Remove
                </IonItemOption>
              </IonItemOptions>
              <IonItem
                onClick={() => {
                  editing
                    ? toggleSelect(screenshot)
                    : openScreenshot(screenshot);
                }}
              >
                {editing ? (
                  selected(screenshot) ? (
                    <IonIcon
                      slot="start"
                      style={menuButtonstyle}
                      icon={checkmarkCircleSharp}
                    />
                  ) : (
                    <IonIcon
                      slot="start"
                      style={menuButtonstyle}
                      icon={ellipseOutline}
                    />
                  )
                ) : (
                  ""
                )}
                <IonLabel>{screenshot}</IonLabel>
              </IonItem>
            </IonItemSliding>
          );
        })}
      </IonList>
    ) : (
      <div id="empty-favorites">
        <div>
          <div>
            <h2>{t("Your screenshots are empty")}</h2>
          </div>
          <p>
            {t(
              "Make screenshots by using Markup in a playing video and hitting the camera icon."
            )}
          </p>
        </div>
      </div>
    );
  }

  function loader() {
    return (
      <div className="loading-container">
        <IonSpinner name="crescent" style={menuButtonstyle} />
      </div>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton class="menu-button" style={menuButtonstyle} />
          </IonButtons>
          <IonTitle>{t("Screenshots")}</IonTitle>
          {screenshots.length ? (
            <IonButtons slot="primary">
              <IonText
                class="edit-button-text"
                style={menuButtonstyle}
                onClick={() => {
                  toggleEdit();
                }}
              >
                {editing ? "Cancel" : "Edit"}
              </IonText>
            </IonButtons>
          ) : (
            ""
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>{loading ? loader() : list()}</IonContent>
      {editing && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonText
                style={menuButtonstyle}
                class="edit-button-text"
                onClick={clearSelections}
              >
                Clear Selections
              </IonText>
            </IonButtons>
            <IonButtons slot="end">
              <IonText
                style={menuButtonstyle}
                class="edit-button-text"
                onClick={remove}
              >
                {selections.length
                  ? `Delete (${selections.length})`
                  : "Delete All"}
              </IonText>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Screenshots;
