const networkReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_NETWORK":
      return action.network;
    default:
      return state;
  }
};

export default networkReducer;
