const languageReducer = (state = "en", action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return action.language;
    default:
      return state;
  }
};

export default languageReducer;
