import authReducer from "./auth";
import counterReducer from "./counter";
import colorReducer from "./color";
import tokenReducer from "./token";
import logoReducer from "./logo";
import practiceReducer from "./practice";
import idReducer from "./id";
import favoritesReducer from "./favorites";
import disclaimerReducer from "./disclaimer";
import languageReducer from "./language";
import brochureReducer from "./brochure";
import searchReducer from "./search";
import sharingReducer from "./sharing";
import markupReducer from "./markup";
import screenshotsReducer from "./screenshots";
import subtitlesReducer from "./subtitles";
import captionsReducer from "./captions";
import sectionsReducer from "./sections";
import networkReducer from "./network";
import platformReducer from "./platform";

import { combineReducers } from "redux";

const allReducers = combineReducers({
  auth: authReducer,
  counter: counterReducer,
  color: colorReducer,
  token: tokenReducer,
  logo: logoReducer,
  practice: practiceReducer,
  id: idReducer,
  favorites: favoritesReducer,
  disclaimer: disclaimerReducer,
  language: languageReducer,
  platform: platformReducer,
  brochure: brochureReducer,
  search: searchReducer,
  sharing: sharingReducer,
  markup: markupReducer,
  screenshots: screenshotsReducer,
  subtitles: subtitlesReducer,
  captions: captionsReducer,
  sections: sectionsReducer,
  network: networkReducer,
});

export default allReducers;
