import { Filesystem, Directory } from "@capacitor/filesystem";
import { FileOpener } from "@ionic-native/file-opener";

export const ensureDir = (dir) => {
  return new Promise(async (resolve, reject) => {
    try {
      await Filesystem.stat({
        path: dir,
        directory: Directory.Documents,
      });
      return resolve(true);
    } catch (e) {
      await Filesystem.mkdir({
        path: dir,
        directory: Directory.Documents,
        recursive: true,
      });
      return resolve(true);
    }
  });
};

export const readDirectory = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const results = await Filesystem.readdir({
        path: payload.directory,
        directory: Directory.Documents,
      });
      return resolve(results);
    } catch (e) {
      console.error("Unable to read directory or directory does not exist.");
      return reject(e);
    }
  });
};

export const removeDirectory = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const results = await readDirectory(payload);
      if (results.files) {
        for (let file of results.files) {
          await Filesystem.deleteFile({
            path: `${payload.directory}/${file}`,
            directory: Directory.Documents,
          });
        }
      }
    } catch (e) {
      console.error(e);
      console.error("Unable to find files in directory.");
      return reject(e);
    }

    try {
      const results = await Filesystem.rmdir({
        path: payload.directory,
        directory: Directory.Documents,
      });
      return resolve(results);
    } catch (e) {
      console.error(e);
      console.error("Unable to remove directory or directory does not exist.");
      return reject(e);
    }
  });
};

export const getUri = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Filesystem.getUri({
        path: `${payload.directory}/${payload.file}`,
        directory: Directory.Documents,
      });
      return resolve(result.uri);
    } catch (e) {
      console.error("Unable to read documents directory");
      return reject(e);
    }
  });
};

export const saveFile = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      await ensureDir(payload.directory);
    } catch (e) {
      console.error(e);
      console.log(`Unable to ensure directory exists: ${payload.directory}`);
      return reject(e);
    }

    try {
      const date = new Date();
      const { hours, ampm } = modHours(date);
      const dateString = `${formatDate(date.getFullYear())}-${formatDate(
        date.getMonth() + 1
      )}-${formatDate(date.getDate())} at ${hours}.${formatDate(
        date.getMinutes()
      )}.${formatDate(date.getSeconds())} ${ampm}`;
      const result = await Filesystem.writeFile({
        path: `${payload.directory}/${payload.filename} ${dateString}.png`,
        data: payload.data,
        directory: Directory.Documents,
      });
      return resolve(result);
    } catch (e) {
      console.error("Unable to write file.");
      return reject(e);
    }
  });
};

const modHours = (d) => {
  let hrs = d.getHours();
  let ampm = hrs > 12 ? "PM" : "AM";
  hrs = hrs % 12;
  return { hours: hrs, ampm };
};

const formatDate = (d) => {
  if (parseInt(d) < 10) {
    return `0${d}`;
  }
  return d;
};

export const openFile = (uri) => {
  console.log(uri);
  return new Promise(async (resolve, reject) => {
    try {
      const opened = await FileOpener.open(uri, "image/png");
      resolve(opened);
    } catch (e) {
      console.error("Unable to open file.");
      return reject(e);
    }
  });
};

export const removeFile = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      await Filesystem.deleteFile({
        path: `${payload.directory}/${payload.filename}`,
        directory: Directory.Documents,
      });
      return resolve(true);
    } catch (e) {
      console.error("Unable to remove file.");
      return reject(e);
    }
  });
};
