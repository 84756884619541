import {
  IonPage,
  IonInput,
  IonContent,
  IonButton,
  IonImg,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import "./Page.css";
import { useDispatch, useSelector } from "react-redux";
import { setId, setToken, setColor, setPractice, setLogo } from "../actions";
import { checkCredentials } from "../authentication";

const Page = () => {
  const [loading, setLoading] = useState(false);
  const network = useSelector((state) => state.network);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [credentialError, setCredentialError] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const usernameInput = useRef(null);
  const passwordInput = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) return;

    let currentUsername = usernameInput.current;
    let currentPassword = passwordInput.current;

    const handleKeyUp = (e) => {
      if (e.key !== "Enter" && e.key !== "Return") return true;
      if (document.activeElement.parentElement === currentUsername) {
        currentPassword.children[0].focus();
      } else if (document.activeElement.parentElement === currentPassword) {
        document.querySelector("#submit-button").click();
      }
    };

    console.log("adding listeners");
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      console.log("removing listeners");
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [loading]);

  async function login(e) {
    e.preventDefault();
    setLoading(true);
    setCredentialError(false);
    setResponseError(false);
    try {
      const result = await checkCredentials(username, password);
      dispatch(setId(result.id));
      dispatch(setLogo(result.preferences.logo));
      dispatch(setColor(result.preferences.color));
      dispatch(setPractice(result.preferences.practice));
      dispatch(setToken(result.token));
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      dispatch(setToken(""));
      if (error === "credentialError") {
        setCredentialError(true);
      } else {
        setResponseError(true);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    setUsername("");
    setPassword("");
  }

  const fields = () => {
    if (!loading) {
      return (
        <form onSubmit={login}>
          <div>
            <IonInput
              ref={usernameInput}
              value={username}
              placeholder="Username"
              type="username"
              onIonChange={(e) => setUsername(e.detail.value)}
            ></IonInput>
            <IonInput
              ref={passwordInput}
              value={password}
              placeholder="Password"
              type="password"
              onIonChange={(e) => setPassword(e.detail.value)}
            ></IonInput>
          </div>
          {credentialError ? (
            <p className="login-error">
              The credentials you entered were invalid. Please try again.
            </p>
          ) : (
            ""
          )}
          {responseError ? (
            <p className="login-error">
              The response from ViewMedica was invalid. Please try again.
            </p>
          ) : (
            ""
          )}
          <div id="button-container">
            <IonButton id="submit-button" color="light" type="submit">
              Login
            </IonButton>
          </div>
        </form>
      );
    } else {
      return (
        <div className="loading-container">
          <IonSpinner name="crescent" color="light" />
        </div>
      );
    }
  };

  return (
    <IonPage>
      <IonContent scroll-y="false" scroll-x="false">
        <div id="login-page">
          <div id="login-form">
            <div id="logo-container">
              <IonImg src="/viewmedica.svg" />
            </div>
            {network ? (
              fields()
            ) : (
              <div id="no-internet-login">
                <h2>No Internet</h2>
                <p>We will automatically detect when you reconnect.</p>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Page;
