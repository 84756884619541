export const checkCredentials = function (username, password) {
  return new Promise(async (resolve, reject) => {
    console.log("Login -> Start");
    const data = new FormData();

    data.append("username", username);
    data.append("password", password);

    try {
      const response = await fetch("https://api.viewmedica.com/ondemand/desktop/login/", {
        method: "POST",
        body: data,
      });
      data.delete("username");
      data.delete("password");
      const result = await response.json();
      if (result.success) {
        console.log("Login -> Success");
        console.log("Login -> Log Prefs", result.preferences);
        console.log("Login -> Log Token", result.token);
        resolve(result);
      } else {
        console.error("Login -> Log Credential Error:", result.message);
        reject("credentialError");
      }
    } catch (error) {
      console.error("Login -> Log Response Error:", error);
      reject("responseError");
    }
  });
};

export const checkToken = function (token) {
  return new Promise(async (resolve, reject) => {
    console.log("Login -> Start");

    const data = new FormData();
    data.append("token", token);

    try {
      const response = await fetch("https://api.viewmedica.com/ondemand/desktop/login/", {
        method: "POST",
        body: data,
      });
      data.delete("token");
      const result = await response.json();
      if (result.success) {
        console.log("Login -> Success");
        console.log("Login -> Log Prefs", result.preferences);
        console.log("Login -> Log Token", result.token);
        resolve(result);
      } else {
        console.error("Login -> Token Error:", result.message);
        reject();
      }
    } catch (error) {
      console.error("Login -> Log Response Error:", error);
      reject();
    }
  });
};
