import { checkToken } from "../authentication";
import {Device} from "@capacitor/device";
import {
  openFile,
  saveFile,
  readDirectory,
  removeDirectory,
} from "../filesystem";

export const initAuthCheck = () => {
  return async function (dispatch, getState) {
    const { token } = getState();
    if (token) {
      dispatch(setAuthChecking());
      try {
        const result = await checkToken(token);
        dispatch(setId(result.id));
        dispatch(setLogo(result.preferences.logo));
        dispatch(setColor(result.preferences.color));
        dispatch(setPractice(result.preferences.practice));
        dispatch(setToken(result.token));
        dispatch(setAuthChecked());
      } catch (e) {
        dispatch(setAuthChecked());
      }
    } else {
      dispatch(setAuthChecked());
    }
  };
};
export const initDeviceCheck = () => {
  return async function (dispatch, getState) {
    const { platform } = getState();
    if (!platform) {
      const { platform } = await Device.getInfo();
      dispatch(setPlatform(platform));
    }
  };
};

export const addScreenshot = (payload) => {
  return async function (dispatch) {
    const { uri } = await saveFile(
      Object.assign({ directory: "screenshots" }, payload)
    );
    dispatch({ type: "MOST_RECENT", uri });
    dispatch(loadScreenshots());
  };
};

export const loadScreenshots = () => {
  return async function (dispatch, getState) {
    dispatch({ type: "SCREENSHOTS_LOAD_START" });
    try {
      const results = await readDirectory({
        directory: "screenshots",
      });
      dispatch({ type: "SET_SCREENSHOTS", screenshots: results.files });
      dispatch({ type: "SCREENSHOTS_LOAD_STOP" });
    } catch (e) {
      dispatch({ type: "SCREENSHOTS_LOAD_STOP" });
    }
  };
};

export const openMostRecentScreenshot = () => {
  return async function (dispatch, getState) {
    const { screenshots } = getState();
    await openFile(screenshots.mru);
  };
};

export const clearScreenshots = () => {
  return async function (dispatch) {
    try {
      await removeDirectory({
        directory: "screenshots",
      });
      dispatch({ type: "SET_SCREENSHOTS", screenshots: [] });
    } catch (e) {
      dispatch({ type: "SET_SCREENSHOTS", screenshots: [] });
    }
  };
};

export const setAuthChecking = () => {
  return {
    type: "AUTH_CHECK",
  };
};

export const setAuthChecked = () => {
  return {
    type: "AUTH_CHECKED",
  };
};

export const setAuthReset = () => {
  return {
    type: "AUTH_RESET",
  };
};

export const increment = (number) => {
  return {
    type: "INCREMENT",
    number: number,
  };
};

export const setId = (id) => {
  return {
    type: "SET_ID",
    id: id,
  };
};

export const setColor = (color) => {
  return {
    type: "SET_COLOR",
    color: color,
  };
};

export const setPractice = (practice) => {
  return {
    type: "SET_PRACTICE",
    practice: practice,
  };
};

export const setLogo = (logo) => {
  return {
    type: "SET_LOGO",
    logo: logo,
  };
};

export const setToken = (token) => {
  return {
    type: "SET_TOKEN",
    token: token,
  };
};

export const addFavorite = (favorite) => {
  return {
    type: "ADD_FAVORITE",
    favorite: favorite,
  };
};

export const setDisclaimer = (disclaimer) => {
  return {
    type: "SET_DISCLAIMER",
    disclaimer: disclaimer,
  };
};

export const setLanguage = (language) => {
  return {
    type: "SET_LANGUAGE",
    language: language,
  };
};

export const setBrochure = (brochure) => {
  return {
    type: "SET_BROCHURE",
    brochure: brochure,
  };
};

export const setSearch = (search) => {
  return {
    type: "SET_SEARCH",
    search: search,
  };
};

export const setSharing = (sharing) => {
  return {
    type: "SET_SHARING",
    sharing: sharing,
  };
};

export const setMarkup = (markup) => {
  return {
    type: "SET_MARKUP",
    markup: markup,
  };
};

export const setSubtitles = (subtitles) => {
  return {
    type: "SET_SUBTITLES",
    subtitles: subtitles,
  };
};

export const setCaptions = (captions) => {
  return {
    type: "SET_CAPTIONS",
    captions: captions,
  };
};

export const setSections = (sections) => {
  return {
    type: "SET_SECTIONS",
    sections: sections,
  };
};

export const removeFavorite = (index) => {
  return {
    type: "REMOVE_FAVORITE",
    index: index,
  };
};

export const removeManyFavorites = (keys) => {
  return {
    type: "REMOVE_FAVORITES",
    keys: keys,
  };
};

export const clearFavorites = () => {
  return {
    type: "CLEAR_FAVORITES",
  };
};

export const setNetwork = (network) => {
  return {
    type: "SET_NETWORK",
    network: network,
  };
};

export const setPlatform = (platform) => {
  return {
    type: "SET_PLATFORM",
    platform: platform,
  };
};

