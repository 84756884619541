import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonToast,
  IonImg,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import { openOutline } from "ionicons/icons";
import React, { useEffect, useState, useRef } from "react";
import "./Page.css";
import { useSelector, useDispatch } from "react-redux";
import {
  addFavorite,
  addScreenshot,
  openMostRecentScreenshot,
} from "../actions";

const Page = () => {
  const [visible, setVisible] = useState(false);
  const [showFavoriteToast, setFavoriteToast] = useState(false);
  const [showScreenshotToast, setScreenshotToast] = useState(false);

  const dispatch = useDispatch();
  const network = useSelector((state) => state.network);
  const color = useSelector((state) => state.color);
  const favorites = useSelector((state) => state.favorites);
  const id = useSelector((state) => state.id);
  const disclaimer = useSelector((state) => state.disclaimer);
  const language = useSelector((state) => state.language);
  const brochure = useSelector((state) => state.brochure);
  const subtitles = useSelector((state) => state.subtitles);
  const captions = useSelector((state) => state.captions);
  const markup = useSelector((state) => state.markup);
  const search = useSelector((state) => state.search);
  const sections = useSelector((state) => state.sections);
  const sharing = useSelector((state) => state.sharing);
  const logo = useSelector((state) => state.logo);
  const practice = useSelector((state) => state.practice);
  const platform = useSelector((state) => state.platform);
  const player = useRef();

  const identity = () => {
    if (logo) {
      return (
        <IonImg
          className="player-logo"
          src={logo}
          width="400px"
          height="100px"
        ></IonImg>
      );
    } else {
      return <IonLabel>{practice}</IonLabel>;
    }
  };

  useEffect(() => {
    const handleKeyboardHide = () => {
      player.current.shadowRoot.querySelector("main").scrollTop = 0;
      document.querySelector("#vm").style.height = "100%";
      document.querySelector("#vm").style.width = "100%";
    };
    const handleKeyboardWillShow = () => {
      player.current.shadowRoot.querySelector(
        "#background-content"
      ).style.background = "black";
      document.querySelector("#vm").style.height =
        document.querySelector("#vm").getBoundingClientRect().height + "px";
      document.querySelector("#vm").style.width =
        document.querySelector("#vm").getBoundingClientRect().width + "px";
    };
    window.addEventListener("keyboardDidHide", handleKeyboardHide);
    window.addEventListener("keyboardWillShow", handleKeyboardWillShow);
    return () => {
      window.removeEventListener("keyboardDidHide", handleKeyboardHide);
      window.removeEventListener("keyboardWillShow", handleKeyboardWillShow);
    };
  });

  useEffect(() => {
    const onScreenshotClick = async function (e) {
      dispatch(
        addScreenshot({
          filename: e.detail.title.replace("- ViewMedica Screenshot.png", ""),
          data: e.detail.data,
        })
      );
      setScreenshotToast(true);
    };

    const onFavoritesClick = async function (e) {
      await dispatch(addFavorite(e.detail, favorites));
      setFavoriteToast(true);
    };

    window.onFavoritesClick = onFavoritesClick;
    window.onScreenshotClick = onScreenshotClick;
  }, [dispatch, favorites]);

  function pauseVideo() {
    if (!window._vm_players) return;
    window._vm_players[
      window._vm_players ? window._vm_players.length - 1 : 0
    ].pauseVideo();
  }

  useEffect(() => {
    console.log("Player container has mounted.");
    setVisible(false);
    window._vm = null;

    let options = {
      api: true,
      apikey: "e5751ac1a513792111d47b68872b2712a9017dba",
      client: id,
      favorites: true,
      lang: language,
      platform,
      openthis: "vm",
      border: false,
      secure: true,
      showhiddenplaylists: true,
      fullscreen: true,
      width: "100%",
      height: "100%",
      dynamicSharing: false,
      callback: () => {
        setTimeout(() => {
          setVisible(true);
        }, 2000);

        if (window._vm_players && window._vm_players.length > 1) {
          console.log(window._vm_players);
          for (var i = 0; i < window._vm_players.length; i++) {
            if (window._vm_players[i].container) {
              window._vm_players[i].container.removeEventListener(
                "onScreenshotClick",
                window.onScreenshotClick
              );
              window._vm_players[i].container.removeEventListener(
                "onFavoritesClick",
                window.onFavoritesClick
              );
              window._vm_players[i].container.addEventListener(
                "onStateChange",
                function (e) {
                  // true if player video is ready
                  console.log(e);
                }
              );
            }
          }
        }

        window._vm.container.addEventListener(
          "onScreenshotClick",
          window.onScreenshotClick
        );

        window._vm.container.addEventListener(
          "onFavoritesClick",
          window.onFavoritesClick
        );
      },
    };

    if (brochure !== "") {
      options["brochure"] = brochure === "true";
    }

    if (captions !== "") {
      options["captions"] = captions === "true";
    }

    if (disclaimer !== "") {
      options["disclaimer"] = disclaimer === "true";
    }

    if (captions !== "") {
      options["captions"] = captions === "true";
    }

    if (markup !== "") {
      options["markup"] = markup === "true";
    }

    if (sections !== "") {
      options["sections"] = sections === "true";
    }

    if (sharing !== "") {
      options["social"] = sharing === "true";
    }

    if (search !== "") {
      options["search"] = search === "true";
    }

    if (sections !== "") {
      options["sections"] = sections === "true";
    }

    if (subtitles !== "") {
      options["subtitles"] = subtitles === "true";
    }

    console.log(options);
    if (id && network) {
      window.vm_open(options);
    }
  }, [
    brochure,
    captions,
    markup,
    sections,
    sharing,
    search,
    subtitles,
    disclaimer,
    id,
    language,
    network,
  ]);

  const menuButtonStyle = {
    color: `#${color}`,
  };

  const playerButtonStyle = {
    background: `#${color}`,
    visibility: visible ? "visible" : "hidden",
  };

  const loading = function () {
    if (!visible && network) {
      return (
        <div className="loading-container">
          <IonSpinner name="crescent" style={menuButtonStyle} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton
              class="menu-button"
              style={menuButtonStyle}
              onClick={pauseVideo}
            />
          </IonButtons>
          <IonTitle>{identity()}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollY="false"
        scrollX="false"
        id="player-content"
        class="player"
        ref={player}
      >
        <div id="top-bar-facade"></div>
        <div id="bottom-bar-facade" style={playerButtonStyle}></div>
        {loading()}
        {network ? (
          <div
            id="vm"
            style={playerButtonStyle}
            className="viewmedica-ionic-player"
          ></div>
        ) : (
          <section id="no-internet">
            <div>
              <h2>No Internet</h2>
              <p>We will automatically detect when you reconnect.</p>
            </div>
          </section>
        )}
      </IonContent>

      <IonToast
        isOpen={showFavoriteToast}
        onDidDismiss={() => setFavoriteToast(false)}
        message="Your favorite has been added."
        duration={2000}
      />

      <IonToast
        style={menuButtonStyle}
        isOpen={showScreenshotToast}
        onDidDismiss={() => setScreenshotToast(false)}
        message="Your screenshot has been saved."
        buttons={[
          {
            text: "Open Screenshot",
            icon: openOutline,
            handler() {
              setScreenshotToast(false);
              dispatch(openMostRecentScreenshot());
            },
          },
        ]}
        duration={2000}
      />
    </IonPage>
  );
};

export default Page;
