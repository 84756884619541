import {
  IonPage,
  IonList,
  IonItem,
  IonContent,
  IonToolbar,
  IonText,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonHeader,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonIcon,
  IonLabel,
  IonFooter,
} from "@ionic/react";
import { ellipseOutline, checkmarkCircleSharp } from "ionicons/icons";
import React, { useState } from "react";
import "./Page.css";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFavorite,
  removeManyFavorites,
  clearFavorites,
} from "../actions";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Page = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const network = useSelector((state) => state.network);
  const favorites = useSelector((state) => state.favorites);
  const language = useSelector((state) => state.language);
  const color = useSelector((state) => state.color);
  const [editing, setEditing] = useState(false);
  const [selections, setSelections] = useState([]);
  const history = useHistory();

  const menuButtonstyle = {
    color: `#${color}`,
  };

  function playFavorite(key) {
    if (network && window._vm_players && window._vm_players.length) {
      window._vm_players[
        window._vm_players ? window._vm_players.length - 1 : 0
      ].navigate(key);
    }
    history.replace("/player/");
  }

  function favoriteLabel(labels) {
    return labels[language] || labels.en || labels;
  }

  const toggleEdit = function () {
    setEditing(!editing);
    if (!editing) {
      clearSelections();
    }
  };

  const toggleSelect = function (i) {
    if (selected(i)) {
      let selected = selections.slice(0);
      selected.splice(selected.indexOf(i), 1);
      setSelections(selected);
    } else {
      setSelections(selections.concat([i]));
    }
  };

  const clearSelections = function () {
    setSelections([]);
  };

  const selected = function (i) {
    return selections.indexOf(i) > -1;
  };

  const remove = async function () {
    if (selections.length > 0) {
      dispatch(removeManyFavorites(selections));
    } else {
      dispatch(clearFavorites());
    }

    setEditing(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton class="menu-button" style={menuButtonstyle} />
          </IonButtons>
          <IonTitle>{t("Favorites")}</IonTitle>
          <IonButtons slot="primary">
            {favorites.length ? (
              <IonText
                class="edit-button-text"
                style={menuButtonstyle}
                onClick={() => {
                  toggleEdit();
                }}
              >
                {editing ? "Cancel" : "Edit"}
              </IonText>
            ) : (
              ""
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {favorites.length ? (
          <IonList>
            {favorites.map((value, index) => {
              return (
                <IonItemSliding key={value.key}>
                  <IonItemOptions side="end">
                    <IonItemOption
                      color="danger"
                      onClick={() => dispatch(removeFavorite(index))}
                    >
                      Remove
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItem
                    onClick={(e) => {
                      e.preventDefault();
                      editing
                        ? toggleSelect(value.key)
                        : playFavorite(value.key);
                    }}
                  >
                    {editing ? (
                      selected(value.key) ? (
                        <IonIcon
                          slot="start"
                          style={menuButtonstyle}
                          icon={checkmarkCircleSharp}
                        />
                      ) : (
                        <IonIcon
                          slot="start"
                          style={menuButtonstyle}
                          icon={ellipseOutline}
                        />
                      )
                    ) : (
                      ""
                    )}
                    <IonLabel>{favoriteLabel(value.labels)}</IonLabel>
                  </IonItem>
                </IonItemSliding>
              );
            })}
          </IonList>
        ) : (
          <div id="empty-favorites">
            <div>
              <h2>{t("Your favorites are empty")}</h2>
              <p>
                {t(
                  'Go to your Video Library. Open a video, presentation or collection. Then select "Add to Favorites" to get started.'
                )}
              </p>
            </div>
          </div>
        )}
      </IonContent>
      {editing && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonText
                style={menuButtonstyle}
                class="edit-button-text"
                onClick={clearSelections}
              >
                Clear Selections
              </IonText>
            </IonButtons>
            <IonButtons slot="end">
              <IonText
                style={menuButtonstyle}
                class="edit-button-text"
                onClick={remove}
              >
                {selections.length
                  ? `Delete (${selections.length})`
                  : "Delete All"}
              </IonText>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Page;
